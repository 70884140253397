<template>
  <b-row @click="onClick">
    <b-col class="filter-divisor" cols="1">
      <img :src="icon" alt="hide"/>
    </b-col>
    <b-col cols="11" xs>
      <p ref="btnShowHide" class="filter-subtitle">{{ title }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'TitleDivisor',
    props: {
        title: {
            type: String,
            default: () => ''
        },
        onClick: {
            type: Function,
            default: () => {
            }
        },
        visible: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            openSidebarIcon: 'icon-minus.svg',
            closedSidebarIcon: 'icon-plus.svg',
        };
    },
    computed: {
        icon() {
            if (this.visible) return this.loadImage(this.openSidebarIcon);
            return this.loadImage(this.closedSidebarIcon);
        },
    },
    methods: {
        loadImage(name) {
            return require(`@/assets/images/${name}`);
        },
    }
};
</script>

<style scoped>
.filter-divisor {
  width: auto;
  flex-grow: 0;
}

.filter-subtitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000740;
}
</style>
