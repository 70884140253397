<template>
  <b-button block
            class="secondary-button"
            :variant="variant"
            :disabled="!isActive"
            @click="onClick">
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
    name: 'SecondaryButton',
    props: {
        onClick: {
            type: Function,
            default: () => {
            }
        },
        buttonText: {
            type: String,
            default: () => this.$t('admin.transactions.detail.refuse-payment')
        },
        operation: {
            type: String,
            default: () => 'sidebar-refuse'
        },
        isActive: {
            type: Boolean,
            default: () => true
        },
        variant: {
            type: String,
            default: () => 'outline-primary'
        }
    }
};
</script>