<template>
  <b-button block variant="primary" @click="onClick(paymentType, transactionId)">
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
    name: 'TransactionButton',
    props: {
        onClick: {
            type: Function,
            default: () => {
            }
        },
        buttonText: {
            type: String,
            default: () => this.$t('admin.transactions.detail.approve-payment')
        },
        operation: {
            type: String,
            default: () => 'sidebar-refuse'
        },
        paymentType: {
            type: String,
            default: () => ''
        },
        transactionId: {
            type: Number,
            default: () => -1
        },
    }
};
</script>