<template>
  <b-dropdown v-if="showMore"
              id="more"
              :text="$t('admin.common.more.title')"
              class="export-dropdown"
              no-caret
              right
              variant="outline-info"
              @mouseleave.native="handleOnLeave"
              @mouseover.native="handleOnHover">
    <template #button-content>
      <b-img :src="moreInfoIcon" alt="export"></b-img>&nbsp;&nbsp;{{ $t('admin.common.more.title') }}&nbsp;
    </template>
    <b-dropdown-item v-for="opt in menuOptions"
                     :key="opt.title"
                     :disabled="!opt.isActive"
                     @click="() => {handleSidebar();opt.handle();}">
      {{ opt.title }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import ApiUtils from '../../utils/ApiUtils';

export default {
    name: 'MoreButton',
    mixins: [ApiUtils],
    data() {
        return {
            refreshHover: false,
        };
    },
    props: {
        showMore: {
            type: Boolean,
            default: true
        },
        handleSidebar: {
            type: Function,
            required: true,
            default: () => {}
        },
        menuOptions: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        moreInfoIcon() {
            return this.loadImage(`icon_more${this.refreshHover ? '-hover' : ''}.svg`);
        },
    },
    methods: {
        handleOnHover() {
            this.refreshHover = true;
        },
        handleOnLeave() {
            this.refreshHover = false;
        }
    }
};
</script>
