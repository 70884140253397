<template>
  <b-row v-if="filterSelected.length > 0" class="active-filter-container">
    <div class="filters">{{ $t('admin.common.active-filter') }}</div>
    <div v-for="(selection, index) in filterSelected" :key="index" class="chosen-filters">
      <FilterComponent
          :is-owner="isOwner"
          :key="index"
          :handle-close-button="removeSelectedFilter"
          :options-company="allCompanies"
          :options-periods="allPeriods"
          :options-transaction-status="paymentStatus"
          :options-transaction-type="paymentTypes"
          :options-status="allStatuses"
          :title="selection"
      />
    </div>
    <div class="reset-filter-align-right">
      <p class="reset-filter" @click="removeAllFilters">
        {{ $t('admin.common.reset-filter') }}
      </p>
    </div>
  </b-row>
</template>

<script>
import FilterComponent from '@/components/Filter/FilterComponent';

export default {
    name: 'FilterWrapper',
    components: { FilterComponent },
    props: {
        filterSelected: {
            type: Array,
            default: () => []
        },
        allCompanies: {
            type: Array,
            default: () => []
        },
        removeSelectedFilter: {
            type: Function,
            default: () => {
            }
        },
        removeAllFilters: {
            type: Function,
            default: () => {
            }
        },
        paymentStatus: {
            type: Object,
            default: () => {
            }
        },
        paymentTypes: {
            type: Object,
            default: () => {
            }
        },
        allPeriods: {
            type: Array,
            default: () => []
        },
        allStatuses: {
            type: Array,
            default: () => []
        },
        isOwner: {
            type: Boolean,
            default: () => false
        },
    }
};
</script>

<style scoped>
.active-filter-container {
  white-space: nowrap;
  justify-content: flex-start;
  align-items: baseline;
}

.reset-filter-align-right {
  align-content: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
}
</style>
