<template>
  <div></div>
</template>

<script>
export default {
    name: 'FilterConstant',
    data() {
        return {
            // FILTER EVENTS
            COMPANY_SELECTED: 'COMPANY-SELECTED',
            COMPANY_REMOVED: 'COMPANY-REMOVED',
            COMPANY_SELECTED_FILTER: 'COMPANY-SELECTED-FILTER',
            COMPANY_REMOVED_FILTER: 'COMPANY-REMOVED-FILTER',
            IN_APP_REMOVED_FILTER: 'IN_APP_REMOVED_FILTER',
            IN_APP_SELECTED_FILTER: 'IN_APP_SELECTED_FILTER',
            USER_TYPE_SELECTED: 'USER-TYPE-SELECTED',
            USER_TYPE_REMOVED: 'USER-TYPE-REMOVED',
            USER_TYPE_SELECTED_FILTER: 'USER-TYPE-SELECTED-FILTER',
            USER_TYPE_REMOVED_FILTER: 'USER-TYPE-REMOVED-FILTER',
            STATUS_SELECTED: 'STATUS-SELECTED',
            IN_APP_STATUS_SELECTED: 'IN_APP_STATUS_SELECTED',
            STATUS_REMOVED: 'STATUS-REMOVED',
            STATUS_SELECTED_FILTER: 'STATUS-SELECTED-FILTER',
            STATUS_REMOVED_FILTER: 'STATUS-REMOVED-FILTER',
            PAYMENT_STATUS_SELECTED_TRANSACTION: 'PAYMENT-STATUS-SELECTED-TRANSACTION',
            PAYMENT_STATUS_REMOVED_TRANSACTION: 'PAYMENT-STATUS-REMOVED-TRANSACTION',
            PAYMENT_STATUS_SELECTED_TRANSACTION_FILTER: 'PAYMENT-STATUS-SELECTED-TRANSACTION-FILTER',
            PAYMENT_STATUS_REMOVED_TRANSACTION_FILTER: 'PAYMENT-STATUS-REMOVED-TRANSACTION-FILTER',
            FROM_DATE_SELECTED: 'FROM-DATE-SELECTED',
            FROM_DATE_REMOVED: 'FROM-DATE-REMOVED',
            FROM_PAYDATE_SELECTED: 'FROM-PAYDATE-SELECTED',
            FROM_PAYDATE_REMOVED: 'FROM-PAYDATE-REMOVED',
            FROM_PAYOUTDATE_SELECTED: 'FROM-PAYOUTDATE-SELECTED',
            FROM_PAYOUTDATE_REMOVED: 'FROM-PAYOUTDATE-REMOVED',
            FROM_DATE_SELECTED_FILTER: 'FROM-DATE-SELECTED-FILTER',
            FROM_DATE_REMOVED_FILTER: 'FROM-DATE-REMOVED-FILTER',
            FROM_PAYDATE_SELECTED_FILTER: 'FROM-PAYDATE-SELECTED-FILTER',
            FROM_PAYDATE_REMOVED_FILTER: 'FROM-PAYDATE-REMOVED-FILTER',
            FROM_PAYOUTDATE_SELECTED_FILTER: 'FROM-PAYOUTDATE-SELECTED-FILTER',
            FROM_PAYOUTDATE_REMOVED_FILTER: 'FROM-PAYOUTDATE-REMOVED-FILTER',
            TO_DATE_REMOVED: 'TO-DATE-REMOVED',
            TO_DATE_SELECTED: 'TO-DATE-SELECTED',
            TO_PAYDATE_REMOVED: 'TO-PAYDATE-REMOVED',
            TO_PAYDATE_SELECTED: 'TO-PAYDATE-SELECTED',
            TO_PAYOUTDATE_SELECTED: 'TO-PAYOUTDATE-SELECTED',
            TO_PAYOUTDATE_REMOVED: 'TO-PAYOUTDATE-REMOVED',
            TO_DATE_SELECTED_FILTER: 'TO-DATE-SELECTED-FILTER',
            TO_DATE_REMOVED_FILTER: 'TO-DATE-REMOVED-FILTER',
            TO_PAYDATE_SELECTED_FILTER: 'TO-PAYDATE-SELECTED-FILTER',
            TO_PAYDATE_REMOVED_FILTER: 'TO-PAYDATE-REMOVED-FILTER',
            TO_PAYOUTDATE_SELECTED_FILTER: 'TO-PAYOUTDATE-SELECTED-FILTER',
            TO_PAYOUTDATE_REMOVED_FILTER: 'TO-PAYOUTDATE-REMOVED-FILTER',
            PAYMENT_TYPE_SELECTED_TRANSACTION: 'PAYMENT-TYPE-SELECTED-TRANSACTION',
            PAYMENT_TYPE_REMOVED_TRANSACTION: 'PAYMENT-TYPE-REMOVED-TRANSACTION',
            PAYMENT_TYPE_SELECTED_TRANSACTION_FILTER: 'PAYMENT-TYPE-SELECTED-TRANSACTION-FILTER',
            PAYMENT_TYPE_REMOVED_TRANSACTION_FILTER: 'PAYMENT-TYPE-REMOVED-TRANSACTION-FILTER',
            FROM_EXEC_DATE_SELECTED:'FROM_EXEC_DATE_SELECTED',
            FROM_EXEC_DATE_REMOVED: 'FROM_EXEC_DATE_REMOVED',
            FROM_EXEC_DATE_SELECTED_FILTER: 'FROM_EXEC_DATE_SELECTED_FILTER',
            FROM_EXEC_DATE_REMOVED_FILTER: 'FROM_EXEC_DATE_REMOVED_FILTER',
            TO_EXEC_DATE_REMOVED: 'TO_EXEC_DATE_REMOVED',
            TO_EXEC_DATE_REMOVED_FILTER: 'TO_EXEC_DATE_REMOVED_FILTER',
            TO_EXEC_DATE_SELECTED_FILTER: 'TO_EXEC_DATE_SELECTED_FILTER',
            TO_EXEC_DATE_SELECTED:'TO_EXEC_DATE_SELECTED',
            // GENERAL EVENTS
            SEARCH_TERM_UPDATED: 'SEARCH-TERM-UPDATED',
            NEW_ITEM_PER_PAGE_SELECTED: 'NEW-ITEM-PER-PAGE-SELECTED',
            NEW_CURRENT_PAGE_SELECTED: 'NEW-CURRENT-PAGE-SELECTED',
            NEW_ALL_ITEMS_SELECTED: 'NEW-ALL-ITEMS-SELECTED',
            PERIOD_SELECTED: 'PERIOD-SELECTED',
            PERIOD_REMOVED: 'PERIOD-REMOVED',
            PERIOD_SELECTED_FILTER: 'PERIOD-SELECTED-FILTER',
            PERIOD_REMOVED_FILTER: 'PERIOD-REMOVED-FILTER',
            PAYROLL_STATUS_SELECTED_FILTER: 'PAYROLL_STATUS-SELECTED-FILTER',
            PAYROLL_STATUS_REMOVED_FILTER: 'PAYROLL_STATUS-REMOVED-FILTER',
            // Transaction Approve/Refuse
            TRANSACTION_APPROVE_REFUSE_RESPONSE: 'RESPONSE-STATUS',
            // RESET Transaction Status
            RESET_TRANSACTION_STATUS: 'RESET_CHOICE',
            SELECT_TRANSACTION_STATUS: 'SELECT_CHOICE',
            // Search
            NEW_SEARCH: 'NEW-SEARCH',
            // Company Threshold
            //  Transactions
            SELECT_COMPANY_TRANSACTION_THRESHOLD: 'SELECT_COMPANY_TRANSACTION_THRESHOLD',
            RESET_COMPANY_TRANSACTION_THRESHOLD: 'RESET_COMPANY_TRANSACTION_THRESHOLD',
            // Company Threshold User based
            SELECT_COMPANY_USER_BASED_THRESHOLD: 'SELECT_COMPANY_USER_BASED_THRESHOLD',
            RESET_COMPANY_USER_BASED_THRESHOLD: 'RESET_COMPANY_USER_BASED_THRESHOLD',
            // Company Threshold user based reached
            SELECT_COMPANY_USER_BASED_THRESHOLD_REACHED: 'SELECT_COMPANY_USER_BASED_THRESHOLD_REACHED',
            RESET_COMPANY_USER_BASED_THRESHOLD_REACHED: 'RESET_COMPANY_USER_BASED_THRESHOLD_REACHED',
            // Company Threshold IBAN
            SELECT_COMPANY_IBAN_THRESHOLD: 'SELECT_COMPANY_IBAN_THRESHOLD',
            RESET_COMPANY_IBAN_THRESHOLD: 'RESET_COMPANY_IBAN_THRESHOLD',
            // Company Zero Gig Threshold
            SELECT_COMPANY_ZERO_GIG_THRESHOLD: 'SELECT_COMPANY_ZERO_GIG_THRESHOLD',
            RESET_COMPANY_ZERO_GIG_THRESHOLD: 'RESET_COMPANY_ZERO_GIG_THRESHOLD',
            // Company Threshold IBAN
            SELECT_PAY_PERIOD_MODIFICATION_THRESHOLD: 'SELECT_PAY_PERIOD_MODIFICATION_THRESHOLD',
            RESET_PAY_PERIOD_MODIFICATION_THRESHOLD: 'RESET_PAY_PERIOD_MODIFICATION_THRESHOLD',
            SELECT_VERIFY_IBAN: 'SELECT_VERIFY_IBAN',
            RESET_VERIFY_IBAN: 'RESET_VERIFY_IBAN',
        };
    }
};
</script>
