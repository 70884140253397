<template>
  <div class="worker-details basic-padding">
    <TitleDivisor
        v-b-toggle.{{sidebar}}
        :on-click="changeVisibilityOfSidebar"
        :title="title"
        :visible="visible"
    />
    <b-collapse :id="sidebar" v-model="visible">
      <b-row>
        <b-col>
          <label for="datepicker-from">{{ $t('admin.common.date-from') }}</label>
          <b-form-datepicker
              :id="idFrom"
              v-model="selectedFromDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              calendar-width="50%"
              class="export-date-picker"
              close-button
              locale="nl"
              reset-button
              size="sm"
              today-button
          ></b-form-datepicker>
        </b-col>
        <b-col>
          <label for="datepicker-to">{{ $t('admin.common.date-to') }}</label>
          <b-form-datepicker
              :id="idTo"
              v-model="selectedToDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              class="export-date-picker"
              close-button
              locale="nl"
              reset-button
              size="sm"
              today-button
          ></b-form-datepicker>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import TitleDivisor from '../Divisor/TitleDivisor';

export default {
    name: 'FilterDatePickerContainer',
    components: {
        TitleDivisor
    },
    props: {
        idFrom: {
            type: String,
            default: () => 'datepicker-from'
        },
        idTo: {
            type: String,
            default: () => 'datepicker-to'
        },
        title: {
            type: String,
            default: () => ''
        },
        eventOnRemoveFromDate: {
            type: String,
            default: () => ''
        },
        eventOnSelectionFromDate: {
            type: String,
            default: () => ''
        },
        eventOnRemoveToDate: {
            type: String,
            default: () => ''
        },
        eventOnSelectionToDate: {
            type: String,
            default: () => ''
        },
    },
    data() {
        return {
            selectedFromDate: '',
            selectedToDate: '',
            visible: true
        };
    },
    computed: {
        sidebar() {
            return `filter_${this.title}`;
        }
    },
    watch: {
        selectedFromDate: {
            handler(selection) {
                this.emitFromDate(selection);
            }
        },
        selectedToDate: {
            handler(selection) {
                this.emitToDate(selection);
            }
        },
    },
    mounted() {
        this.$root.$on(this.eventOnRemoveFromDate, (newFromDate) => {
            this.selectedFromDate = newFromDate;
        });
        this.$root.$on(this.eventOnRemoveToDate, (newToDate) => {
            this.selectedToDate = newToDate;
        });
    },
    methods: {
        emitFromDate(selection) {
            this.$root.$emit(this.eventOnSelectionFromDate, selection);
        },
        emitToDate(selection) {
            this.$root.$emit(this.eventOnSelectionToDate, selection);
        },
        changeVisibilityOfSidebar() {
            this.visible = !this.visible;
        }
    }
};
</script>
