<template>
  <div v-if="options.length > 0" class="worker-details basic-padding">
    <TitleDivisor
        v-b-toggle.{{sidebar}}
        :on-click="changeVisibilityOfSidebar"
        :title="title"
        :visible="isVisible"
    />
    <b-collapse :id="sidebar" :visible="isVisible">
      <b-row>
        <b-col>
          <b-form-group>
            <template v-if="showStatusAggregated" #label>
              <b-form-checkbox
                  v-model="allProblematicSelected"
                  :indeterminate="indeterminate"
                  name="select Problematic"
                  stacked
                  @change="selectAllProblematic"
              >
                {{ $t('admin.transactions.status-problematic') }}
              </b-form-checkbox>
            </template>
            <b-form-checkbox-group
                v-model="selected"
                :name="name"
                :options="options"
                stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-collapse>
  </div>

</template>
<script>
import TitleDivisor from '@/components/Filter/Divisor/TitleDivisor';
import PaymentStatus from '@/utils/PaymentStatus';
import generateRandomNumber from "@/utils/RandomGenerator";
import WorkerStatus from "@/utils/WorkerStatus";

export default {
    name: 'FilterCheckBoxContainer',
    components: {
        TitleDivisor
    },
    props: {
        name: {
            type: String,
            default: () => `${generateRandomNumber()}`
        },
        options: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: () => ''
        },
        eventOnRemove: {
            type: String,
            default: () => ''
        },
        eventOnSelection: {
            type: String,
            default: () => ''
        },
        showStatusAggregated: {
            type: Boolean,
            default: () => false
        },
        isOpen: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        isTransactions: {
            type: Boolean,
            default: () => {
                return true;
            }
        }
    },
    data() {
        return {
            selected: [],
            visible: true,
            allProblematicSelected: false,
            indeterminate: false,
        };
    },
    computed: {
        sidebar() {
            return `filter_${this.title}`;
        },
        isVisible() {
            // != is XOR operator. This handle the correct status of the visibility of the filter.
            return !this.isOpen != this.visible;
        }
    },
    watch: {
        selected: {
            handler(selection) {
                this.emitNewEvent(selection);
            }
        },
    },
    mounted() {
        this.$root.$on(this.eventOnRemove, (newSelection) => {
            this.selected = newSelection;
        });
    },
    methods: {
        selectAllProblematic(checked) {
            const statusForTransaction = [PaymentStatus.FAILED.symbol,
                PaymentStatus.REJECTED.symbol,
                PaymentStatus.REFUSED.symbol,
                PaymentStatus.INPROGRESS.symbol,
                PaymentStatus.ON_HOLD.symbol,
                PaymentStatus.SCHEDULED.symbol,
                PaymentStatus.TOCONFIRM.symbol];
            const statusForWorkers = [WorkerStatus.PAYMENT_ON_HOLD.symbol, WorkerStatus.NEED_APPROVAL.symbol];
            const problematicStatuses = this.isTransactions ? statusForTransaction : statusForWorkers;
            this.selected = checked ? problematicStatuses.slice() : [];

        },
        emitNewEvent(selection) {
            this.$root.$emit(this.eventOnSelection, selection);
        },
        changeVisibilityOfSidebar() {
            this.visible = !this.visible;
        }
    }
};
</script>
