<template>
  <div class="active-filter">
    <p class="active-filter-title">{{ getFilterTitle(title) }}</p>
    <img alt="remove-filter" class="active-filter-close"
         src="@/assets/images/icon-close-button-blue.svg"
         @click="handleCloseButton(title)"/>
  </div>
</template>

<script>
export default {
    name: 'FilterComponent',
    props: {
        title: {
            type: [String, Number, Object],
            default: () => 'No Title',
        },
        handleCloseButton: {
            type: Function,
            default: () => {
            }
        },
        optionsCompany: {
            type: Array,
            default: () => []
        },
        optionsBackendError: {
            type: Object,
            default: () => {
            }
        },
        optionsTransactionType: {
            type: Object,
            default: () => {
            }
        },
        optionsTransactionStatus: {
            type: Object,
            default: () => {
            }
        },
        optionsPeriods: {
            type: Array,
            default: () => []
        },
        optionsStatus: {
            type: Array,
            default: () => []
        },
        optionsFees: {
            type: Array,
            default: () => []
        },
        isOwner: {
            type: Boolean,
            default: () => false
        },
    },
    methods: {
        getFilterTitle(title) {
            if (typeof title === 'string') {
                if (title !== '') {
                    return this.extractName(title);
                } else {
                    return;
                }
            }
            if (this.isCreationDate(title) && this.isCreationDateFrom(title)) {
                return `${this.$t('admin.common.date-from')}: ${title.fromDate}`;
            } else if (this.isCreationDate(title) && this.isCreationDateTo(title)) {
                return `${this.$t('admin.common.date-to')}: ${title.toDate}`;
            }
            if (this.isExecutionDate(title) && this.isExecutionDateFrom(title)) {
                return `${this.$t('admin.transactions.detail.execution-date')} ${this.$t('admin.common.date-from')}: ${title.execFromDate}`;
            } else if (this.isExecutionDate(title) && this.isExecutionDateTo(title)) {
                return `${this.$t('admin.transactions.detail.execution-date')} ${this.$t('admin.common.date-to')}: ${title.execToDate}`;
            }

            if (this.optionsCompany && this.optionsCompany.length > 0) {
                return this.extractCompanyName(title);
            }

            return 'Unknown Company Name';
        },
        isCreationDate(title) {
            return title.fromDate !== undefined || title.toDate !== undefined;
        },
        isCreationDateFrom(title) {
            return title.fromDate !== undefined;
        },
        isCreationDateTo(title) {
            return title.toDate !== undefined;
        },
        isExecutionDate(title) {
            return title.execFromDate !== undefined || title.execToDate !== undefined;
        },
        isExecutionDateFrom(title) {
            return title.execFromDate !== undefined;
        },
        isExecutionDateTo(title) {
            return title.execToDate !== undefined;
        },
        extractCompanyName(companyName) {
            const defaultCompanyName = 'Loading...';
            if (this.optionsCompany && this.optionsCompany.length > 0) {
                const response = this.optionsCompany
                    .filter(company => parseInt(company.value) === parseInt(companyName));
                if (response.length > 0) {
                    return response[0].text;
                }
            }
            return defaultCompanyName;
        },
        extractName(name) {
            if (this.optionsBackendError) {
                return this.optionsBackendError[name];
            }
            if (this.optionsTransactionType || this.optionsTransactionStatus) {
                return this.optionsTransactionType[name] ? this.optionsTransactionType[name] : this.optionsTransactionStatus[name].name;
            }
            if (this.isOpen(name)) return this.$t('admin.invoice.status.open');
            if (this.isEarlyPaid(name)) return this.$t('admin.invoice.status.early-paid');
            if (this.isPaid(name)) return this.$t('admin.invoice.status.paid');

            let stringResponse = name.includes('_') ? name.replaceAll('_', ' ') : name;
            stringResponse = stringResponse.includes(',') ? stringResponse.replaceAll(',', ', ') : stringResponse;
            return `${stringResponse[0].toUpperCase()}${stringResponse.slice(1, stringResponse.length)
                .toLowerCase()}`;
        },
        isPaid(name) {
            return name === 'PAY_COMPLETED,PAYC_COMPLETED,EXECUTED';
        },
        isEarlyPaid(name) {
            return name === 'EARLY_PAID';
        },
        isOpen(name) {
            return name === 'PAYC_STORED';
        }
    }
};
</script>