<template>
  <b-dropdown v-if="showExport"
              id="exporter"
              :disabled="!isAbleToExport"
              :text="$t('admin.active-worker.export-title')"
              class="export-dropdown"
              no-caret
              right
              variant="outline-info"
              @mouseleave.native="handleOnLeave"
              @mouseover.native="handleOnHover">
    <template #button-content>
      <b-img :src="refreshIcon" alt="export"></b-img>&nbsp;&nbsp;{{ $t('admin.active-worker.export-title') }}&nbsp;
    </template>
    <b-dropdown-item @click="handleExporter('pdf')">
      {{ $t('admin.active-worker.export') }} pdf
    </b-dropdown-item>
    <b-dropdown-item @click="handleExporter('xlsx')">
      {{ $t('admin.active-worker.export') }} xlsx
    </b-dropdown-item>
    <b-dropdown-item @click="handleExporter('csv')">
      {{ $t('admin.active-worker.export') }} csv
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import ApiUtils from '@/utils/ApiUtils';

export default {
    name: 'ExporterButton',
    mixins: [ApiUtils],
    data() {
        return {
            refreshHover: false,
        };
    },
    props: {
        handleExporter: {
            type: Function,
            default: () => {
            }
        },
        exportDisabled: {
            type: Boolean,
            default: true
        },
        showExport: {
            type: Boolean,
            default: true
        },
        isAbleToExport: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        refreshIcon() {
            switch (true) {
            case (this.isAbleToExport && this.refreshHover):
                return this.loadImage("ic_export-hover.svg");
            case (this.isAbleToExport && !this.refreshHover):
                return this.loadImage("ic_export.svg");
            case (!this.isAbleToExport):
                return this.loadImage("ic_export-disabled.svg");
            default:
                return this.loadImage("ic_export.svg");
            }
        }
    },
    methods: {
        handleOnHover() {
            this.refreshHover = true;
        },
        handleOnLeave() {
            this.refreshHover = false;
        }
    }
};
</script>
