<template>
  <b-button v-if="showButton" v-b-toggle.{{sidebar}} class="export-button" variant="primary" @click="handleSidebar">
    {{ $t('admin.companies.onboard') }}
  </b-button>
</template>
<script>
export default {
    name: 'AddCompanyButton',
    props: {
        sidebar : {
            type: String,
            required: true,
            default: () => ('onboardCompany'),
        },
        showButton: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        handleSidebar: {
            type: Function,
            required: true,
            default: () => {},
        },
    },
};
</script>
