<template>
  <div :class="isWrapped ? 'fixed-bottom white-bg button-wrapper' : 'fixed-bottom white-bg button-padding'">
    <slot name="mainButton"/>
    <slot name="secondaryButton"/>
  </div>
</template>

<script>

export default {
    name: 'ButtonContainer',
    props: {
        isWrapped: {
            type: Boolean,
            default: () => false
        },
    }
};
</script>